export interface NFT {
    id: string;
    image: string;
    nfcID: string;
  }
  
  export interface Boost {
    id: number,
    boost_token_id: number;
    boost_type: string;
    value: number;
    duration: number;
    uses_left: number | null;
    is_active: boolean;
    start_time: string;
    metadata_hash: string;
    image_url: string;
  }
  
  export interface NFTListProps {
    uid: string;
    jwtToken: string | null;
    onMatchingNFTFound: (nft: NFT) => void;
    onPairSuccess: (result: PairingResult) => void;
    piccData: string;
    enc: string;
    cmac: string;
  }

  export interface TokenRedemptionProps {
    uid: string;
    nfcStatus: number | string | null;
    piccData: string;
    enc: string;
    cmac: string;
    nft_token_id: number | null;
    nft_collection_id: number | null;
  }
  
  export enum Step {
    ConnectWallet = 1,
    PairNFT = 2,
    PairingSuccess = 3,
    SelectBoosts = 4,
    ClaimTokens = 5,
    ClaimSuccess = 6
  }

  export interface PairingResult {
    baseAmount: number;
    boostedAmount: number;
    boostPercentage: number;
    totalAmount: number;
  }

  export interface ClaimResult {
    baseAmount: number;
    boostedAmount: number;
    boostPercentage: number;
    totalAmount: number;
  }
  