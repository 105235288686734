import React, { useState, useEffect } from 'react';
import './App.css';
import { TokenRedemption } from './components/TokenRedemption';
import { we3modalConfig } from './configs/web3modal-config';
import { createWeb3Modal } from '@web3modal/ethers/react';

createWeb3Modal(we3modalConfig)

interface NFCData {
  uid: string;
  status: number | string | null;
  piccData: string;
  enc: string;
  cmac: string;
  nft_token_id: number | null;
  nft_collection_id: number | null;
}

function App() {
  const [nfcData, setNfcData] = useState<NFCData | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const piccData = urlParams.get('picc_data');
    const enc = urlParams.get('enc');
    const cmac = urlParams.get('cmac');

    if (piccData && enc && cmac) {
      fetchNfcData(piccData, enc, cmac);
    } else {
      setErrorMessage('Please scan a valid phygital item');
    }
  }, []);

  const fetchNfcData = async (piccData: string, enc: string, cmac: string) => {
    try {
      const params = new URLSearchParams({ picc_data: piccData, enc, cmac });

      const response = await fetch(`${process.env.REACT_APP_BACKEND_API}/nfc/tag?${params.toString()}`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch NFC data');
      }

      const data = await response.json();
      if (data.uid) {
        setNfcData({
          uid: data.uid,
          status: data.status,
          piccData,
          enc,
          cmac,
          nft_token_id: data.nft_id,
          nft_collection_id: data.collection_id || 1,
        });
        console.log(data);
      } else {
        setErrorMessage('Please scan a valid phygital item');
      }
    } catch (error) {
      setErrorMessage('Error fetching NFC data');
      console.error('Error:', error);
    }
  };

  return (
    <div className="App">
      <main style={{backgroundColor: 'rgb(12, 12, 12)'}}>
        <div id="main-page-content" className="content-full-width">
          <div className="content-row row_padding_bottom dark-section text-align-center" data-bgcolor="#0c0c0c">
            {errorMessage ? (
              <h2 className="big-title has-mask-fill primary-font-title">{errorMessage}</h2>
            ) : nfcData ? (
              <TokenRedemption 
                uid={nfcData.uid} 
                nfcStatus={nfcData.status}
                piccData={nfcData.piccData}
                enc={nfcData.enc}
                cmac={nfcData.cmac}
                nft_token_id={nfcData.nft_token_id}
                nft_collection_id={nfcData.nft_collection_id}
              />
            ) : (
              <h2 className="big-title has-mask-fill primary-font-title">Scanning NFC Tag...</h2>
            )}
          </div>
        </div>
      </main>
    </div>
  );
}

export default App;