import React, { useState, useEffect } from 'react';
import { useWeb3ModalAccount, useWeb3ModalProvider, useWeb3Modal } from '@web3modal/ethers/react';
import { BrowserProvider, ethers, Contract, getBytes} from 'ethers';
import PhygitalCollectionABI from '../configs/PhygitalCollectionABI.json';
import { NFT, Boost, NFTListProps, PairingResult } from './types';
import { fetchBoosts } from './boostService';
import MainButton from './MainButton';
import ConnectButton from './ConnectButton';

const CONTRACT_ADDRESS = process.env.REACT_APP_CONTRACT_ADDRESS!
const IPFS = process.env.REACT_APP_IPFS!
const COLLECTION_ID = Number(process.env.REACT_APP_COLLECTION_ID)!
const DEFAULT_IMAGE = 'https://via.placeholder.com/150?text=NFT';

export function NFTList({ uid, jwtToken, onMatchingNFTFound, onPairSuccess, piccData, enc, cmac }: NFTListProps) {
  const [nfts, setNfts] = useState<NFT[]>([]);
  const [selectedNft, setSelectedNft] = useState<string | null>(null);
  const [pairingStatus, setPairingStatus] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  
  const [connectedContract, setConnectedContract] = useState<Contract | undefined>(undefined);
  const [error, setError] = useState<string | null>(null);
  const { address, isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();
  const { open } = useWeb3Modal();
  const [boosts, setBoosts] = useState<Boost[]>([]);
  const [selectedBoostIndices, setSelectedBoostIndices] = useState<number[]>([]);
  const [isPairing, setIsPairing] = useState(false);
  const [pairingResult, setPairingResult] = useState<PairingResult | null>(null);
  

  const initContract = async () => {
    if (!walletProvider) return;
    const provider = new BrowserProvider(walletProvider);
    const signer = await provider.getSigner();
    const contract = new Contract(CONTRACT_ADDRESS, PhygitalCollectionABI, signer);
    setConnectedContract(contract);
    console.log('contract initialized', contract);
    return contract;
  };

  const fetchNFTs = async (contract: Contract) => {
    console.log("Starting nft fetch");
    setIsLoading(true);
    setError(null);

    try {
      const balance = await contract.balanceOf(address);
      const tokenIds = [];
      for (let i = 0; i < balance; i++) {
        const tokenId = await contract.tokenOfOwnerByIndex(address, i);
        tokenIds.push(tokenId.toString());
      }

      const nftPromises = tokenIds.map(async (tokenId) => {
        try {
          const tokenURI = await contract.tokenURI(tokenId);
          console.log(`Token URI for ${tokenId}:`, tokenURI);

          let metadata;
          let imageUrl = DEFAULT_IMAGE;
          try {
            const response = await fetch(tokenURI);
            metadata = await response.json();
            console.log(`Metadata`, metadata);
            imageUrl = metadata.image;
            console.log(`Image URL for ${tokenId}:`, imageUrl);

            // Check if the image loads
            const imageLoads = await checkImageLoads(imageUrl);
            if (!imageLoads) {
              console.log(`Image failed to load for ${tokenId}, using DEFAULT_IMAGE`);
            }
          } catch (error) {
            console.error(`Error fetching metadata for token ${tokenId}:`, error);
          }
  
          // Check if the NFT has an NFC connected
          const nfcID = await contract.getNFCId(tokenId);
          console.log(`NFCID for ${tokenId}:`, nfcID);
  
          return {
            id: tokenId.toString(),
            image: imageUrl,
            nfcID: nfcID.toString()
          } as NFT;
        } catch (error) {
          console.error(`Error fetching metadata for token ${tokenId}:`, error);
          return {
            id: tokenId.toString(),
            image: DEFAULT_IMAGE,
            nfcID: ''
          } as NFT;
        }
      });

      const fetchedNFTs = await Promise.all(nftPromises);
      console.log("Fetched NFTs:", fetchedNFTs);
  
      // Check for matching NFT
      const matchingNFT = fetchedNFTs.find(nft => nft.nfcID === uid);
      if (matchingNFT) {
        onMatchingNFTFound(matchingNFT);
        return;
      }
  
      // Filter out NFTs that already have an NFC connected
      const filteredNFTs = fetchedNFTs.filter(nft => nft.nfcID === '');
      setNfts(filteredNFTs);
    } catch (error) {
      console.error('Failed to get NFT info:', error);
      setError('Failed to fetch NFTs');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const setup = async () => {
      if (isConnected && address && walletProvider && jwtToken) {
        const contract = await initContract();
        if (contract) {
          await fetchNFTs(contract);
          const fetchedBoosts = await fetchBoosts(jwtToken);
          console.log(fetchedBoosts);
          setBoosts(fetchedBoosts);
        }
      }
    };
    setup();
  }, [isConnected, walletProvider, address, jwtToken]);


  // Helper function to check if an image loads
  const checkImageLoads = (url: string): Promise<boolean> => {
    return new Promise((resolve) => {
      const img = new Image();
      img.onload = () => resolve(true);
      img.onerror = () => resolve(false);
      img.src = url;
    });
  };


  const handlePair = async () => {
    if (!selectedNft || !jwtToken || !connectedContract) return;

    try {
      setPairingStatus('');
      setIsPairing(true);

      const deadline = Math.floor(Date.now() / 1000) + 180; // 3 minutes from now

      const boostsPayload = selectedBoostIndices.map(index => boosts[index].id);

      // Get signature from backend
      const pairResponse = await fetch(`${process.env.REACT_APP_BACKEND_API}/nfc/pair`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${jwtToken}`
        },
        body: JSON.stringify({
          nft_id: parseInt(selectedNft),
          collection_id: COLLECTION_ID,
          boosts: boostsPayload,
          deadline: deadline,
          picc_data: piccData,
          enc: enc,
          cmac: cmac
        }),
      });

      if (!pairResponse.ok) {
        const errorData = await pairResponse.json();
        setError(errorData.error);
        throw new Error(errorData.error || `Failed to pair: ${pairResponse.status}`);
        
      }

      const pairData = await pairResponse.json();

      // Call firstClaim function
      const provider = new BrowserProvider(walletProvider!);
      const signer = await provider.getSigner();
      const contract = new ethers.Contract(CONTRACT_ADDRESS, PhygitalCollectionABI, signer);

      console.log("Pair data:", pairData);



      console.log('Calling firstClaim with params:', {
        nftId: parseInt(selectedNft),
        boost: pairData.totalBoostValueStr,
        deadline: deadline,
        uid: uid,
        signature: '0x'+pairData.signature
      });

      const tx = await contract.firstClaim(
        parseInt(selectedNft),
        pairData.totalBoostValueStr,
        deadline,
        uid,
        '0x'+pairData.signature
      );

      console.log('Transaction sent:', tx.hash);
      setPairingStatus('Transaction sent. Waiting for confirmation...');

      const receipt = await tx.wait();
      console.log('Transaction confirmed:', receipt);

      // Find the Transfer event in the logs
      const transferEvent = receipt.logs.find(
        (log: any) => log.topics[0] === ethers.id("Transfer(address,address,uint256)")
      );

      if (transferEvent) {
        const amount = ethers.formatEther(transferEvent.data);
        const totalAmount = parseFloat(amount);
        const boostAmount = parseFloat(ethers.formatUnits(pairData.totalBoostValueStr, 25));

        const baseAmount = totalAmount/(1 + boostAmount/100)
        
        const result: PairingResult = {
          baseAmount,
          boostedAmount: totalAmount - baseAmount,
          boostPercentage: boostAmount,
          totalAmount
        };
    
        onPairSuccess(result);
      } else {
        setError('NFT paired successfully, but couldn\'t find token transfer amount.');
      }
      
    } catch (error) {
      console.error('Error pairing NFT:', error);
      // setError('Error pairing NFT: '+ error);
      if (error instanceof Error) {
        setError(error.message);
      } else {
        setError('Error pairing NFT: '+ error);
      }
    } finally {
      setIsPairing(false);
    }
  };

  // Helper function to get a user-friendly error message
  const getUserFriendlyErrorMessage = (error: any): string => {
    if (typeof error === 'string') {
      if (error.includes('user rejected') || error.includes('User denied')) {
        return "Transaction canceled by user.";
      } else if (error.includes('is already paired with NFT with ID')) {
        return "Looks like this item is already paired. Please refresh the page or contact support.";
      }
    } else if (error instanceof Error) {
      if (error.message.includes('user rejected') || error.message.includes('User denied')) {
        return "Transaction canceled by user.";
      } else if (error.message.includes('There is already an active pairing for this NFC tag or NFT')) {
        return "Looks like this item is already paired. Please refresh the page or contact support.";
      }
      if (error.message.includes('estimateGas')) {
        return "Not enough ETH to pay for gas. Please top up your wallet.";
      }
    } 

    console.log(error);
  
    // Add more specific error checks here if needed
    return "An error occurred while processing your transaction. Please try again.";
  };

  // Render function for NFT grid
  const renderNFTGrid = () => (
    <div>
      <p className="has-mask-fill">Select one NFT to pair with your phygital item</p>
      <div className="nft-grid">
        {nfts.map((nft) => (
          <div 
            key={nft.id} 
            className={`nft-item ${selectedNft === nft.id ? 'selected' : ''}`}
            onClick={() => setSelectedNft(nft.id)}
          >
            <img src={nft.image} alt={`NFT ${nft.id}`} onError={(e) => { e.currentTarget.src = DEFAULT_IMAGE; }} />
            <p>ID: {nft.id}</p>
          </div>
        ))}
      </div>
    </div>
  );

  const handleBoostSelection = (index: number) => {
    setSelectedBoostIndices(prevIndices => {
      if (prevIndices.includes(index)) {
        return prevIndices.filter(i => i !== index);
      } else {
        return [...prevIndices, index];
      }
    });
  };

  // Render function for boost grid
  const renderBoostGrid = () => (
    <div>
      <h4 className="has-mask-fill">Select your boosts</h4>
      {boosts.length > 0 ? (
        <div className="nft-grid">
          {boosts.map((boost, index) => (
            <div 
              key={index} 
              className={`nft-item ${selectedBoostIndices.includes(index) ? 'selected' : ''}`}
              onClick={() => handleBoostSelection(index)}
            >
              <img src={boost.image_url.replace('ipfs://', IPFS)} alt={`Boost ${boost.boost_type}`} />
              <hr />
              <div className="white">
                <span>{boost.boost_type}</span>
                <span> {boost.value ? (boost.value / 100) + "%" : "?"}</span>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div>
          <p>You don't have any boosts yet.</p>
          {/* <MainButton
            text="Mint a Boost"
            hoverText="Get Your First Boost"
            handler={handleMintBoost}
          /> */}
        </div>
      )}
    </div>
  );
  const handleMintBoost = () => {
    // Implement the logic to mint a boost
    console.log("Minting boost...");
  };

  if (!isConnected) {
    return (
      <div>
        <h2 className="big-title has-mask-fill primary-font-title">CLAIM YOUR TOKENS</h2>
        <ConnectButton type="connect" />
      </div>
    );
  }

  if (isLoading) return <h2 className="big-title has-mask-fill primary-font-title">Loading</h2>;


  return (
    <div>
      <h2 className="big-title has-mask-fill primary-font-title">Pair your digital twin</h2>
      {nfts.length === 0 ? (
        <p>No unpaired NFTs found for this address. Please try another wallet.</p>
      ) : (
        <>
          {!selectedNft ? renderNFTGrid() : (
            <>
              <div className="info-line">
                <span className="info-label">Selected NFT ID:</span>
                <span className="info-value">{selectedNft}</span>
              </div>
              
              {renderBoostGrid()}
              <MainButton
                text="Pair Now"
                hoverText="Pair Digital Twin"
                loadingText="Pairing"
                handler={handlePair}
                disabled={!selectedNft}
                loading={isPairing}
              />
            </>
          )}
        </>
      )}
      
      
      
      {error && (
        <div className="sticky-error-alert">
          <div className="alert alert-error">
            {getUserFriendlyErrorMessage(error)}
          </div>
        </div>
      )}
    </div>
  );
}